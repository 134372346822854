import { useQueryClient, useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import * as amplitude from '@amplitude/analytics-browser'
import useStore from '@src/store'

import { AccountKeys, OrgStructureKeys } from '../Keys'

import { useCreate, useDelete, useGetList, useGetOne, usePatch, useUpdate } from '../request'

import {
  Account,
  AccountLoginRequest,
  AccountSignupRequest,
  AccountMyTeamRequest,
  Verify2FALoginRequest,
  Verify2FALoginResponse,
  GetTwoFAConfigsRequest,
  Create2FARequest,
  Create2FAResponse,
  Verify2FARequest,
  Verify2FAResponse,
  UserSettings,
  JoinTeamRequest,
  AccountLoginResponse,
} from '@models/Account'
import { ContactType } from '@type/common'

import api from '@src/api/api'
import { generatePath } from 'react-router-dom'

export const PATHS = {
  me: '/api/my-account',
  team: '/api/my-account/team',
  login: '/api/auth/login/',
  confirmEmail: '/confirmation-email/',
  resendConfirmEmail: '/resend-confirmation-email',
  slack: '/slack/login/token?k=',
  signup: '/api/auth/register/',
  resetPassword: '/api/password-reset/',
  resetPasswordConfirm: '/api/password-reset/confirm/',
  contactSales: 'api/contact',
  myTeam: 'api/my-team',
  teams: 'api/teams',
  joinTeamRequest: '/api/teams/join-requests',
  joinTeamApprove: '/api/teams/join-requests/:id',
  password: 'auth/password',
  twoFA: '/api/authenticators',
  twoFADelete: '/api/authenticators/:type',
  verify2FA: '/api/authenticators/:type/verify',
  verify2FALogin: '/api/auth/verify',
  logout: 'auth/logout/',
  onboarding: '/api/auth/onboarding',
  settings: '/api/my-account/settings',
}

export const useGetMyAccount = (
  { refetchOnWindowFocus }: { refetchOnWindowFocus?: boolean | 'always' } = { refetchOnWindowFocus: false },
) => {
  const token = localStorage.getItem('token')
  return useGetOne<Account>([AccountKeys.Me], PATHS.me, {
    query: {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      enabled: !!token,
      refetchOnWindowFocus: refetchOnWindowFocus || false,
    },
  })
}

export const useLogIn = () => {
  return useCreate<AccountLoginRequest, AccountLoginResponse>(PATHS.login)
}
export const useLogOut = () => {
  const cache = useQueryClient()
  return useCreate<unknown>(PATHS.logout, {
    mutation: {
      mutationKey: [AccountKeys.LogOut],
      onSuccess: () => {
        api.defaults.headers.Authorization = ''
        amplitude.reset()
        localStorage.clear()
        Cookies.remove('token')
        Cookies.remove('refreshToken')
        cache.resetQueries()
        useStore.getState().reset()
      },
    },
  })
}

export const useSignUp = () => {
  return useCreate<Partial<AccountSignupRequest>, AccountLoginResponse>(PATHS.signup)
}

export const useActivate = (key: string) => {
  return useMutation<AccountLoginResponse>({
    mutationKey: [AccountKeys.Activate],
    mutationFn: async () => {
      const data = (await api.get<AccountLoginResponse>(`${PATHS.confirmEmail}${key}`)).data

      return data
    },
  })
}

export const useResendConfirm = () => {
  return useCreate<{ email: string }>(PATHS.resendConfirmEmail)
}

export const useGetSlackToken = () => {
  return useGetOne<{ auth_token: string }>([AccountKeys.SlackToken], PATHS.slack)
}

export const useResetPassword = (isReset = false) => {
  return useCreate<{ token?: string; password?: string; email?: string }>(
    isReset ? PATHS.resetPasswordConfirm : PATHS.resetPassword,
  )
}

export const useGetTeams = () => {
  return useGetList<{ id: number; name: string }>([AccountKeys.Teams], PATHS.teams)
}

export const useCreateRequestJoinTeam = () => {
  const cache = useQueryClient()
  return useCreate<{ team: number }>(PATHS.joinTeamRequest, {
    mutation: {
      mutationKey: [AccountKeys.JoinTeamRequest],
      onSuccess: () => {
        cache.invalidateQueries({ queryKey: [AccountKeys.JoinTeamRequest] })
      },
    },
  })
}

export const useApproveJoinTeam = () => {
  const cache = useQueryClient()
  return useCreate<string>((id) => generatePath(PATHS.joinTeamApprove, { id: id }), {
    mutation: {
      mutationKey: [AccountKeys.JoinTeamApprove],
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      },
    },
  })
}

export const useGetJoinRequest = (enabled = true) => {
  return useGetOne<JoinTeamRequest>([AccountKeys.JoinTeamRequest], PATHS.joinTeamRequest, {
    query: {
      enabled,
    },
  })
}

export const useUpdateTeamInfo = () => {
  const cache = useQueryClient()
  return usePatch<Partial<AccountMyTeamRequest>>(PATHS.myTeam, {
    mutation: {
      mutationKey: [AccountKeys.MyTeam],
      onSuccess: () => {
        cache.invalidateQueries([AccountKeys.Me])
      },
    },
  })
}

export const useUpdatePassword = () => {
  const cache = useQueryClient()
  return useUpdate<{ new_password: string; repeat_password: string; current_password: string | null }>(PATHS.password, {
    mutation: {
      mutationKey: [AccountKeys.Password],
      onSuccess: () => {
        cache.invalidateQueries([AccountKeys.Me])
      },
    },
  })
}

export const useGetTwoFAConfigs = () => {
  return useGetList<GetTwoFAConfigsRequest>([AccountKeys.TwoFAList], PATHS.twoFA)
}

export const useCreate2FA = () => {
  return useCreate<Create2FARequest, Create2FAResponse>(PATHS.twoFA)
}

export const useDelete2FA = () => {
  return useDelete(PATHS.twoFADelete)
}

export const useVerify2FA = () => {
  return useCreate<Verify2FARequest, Verify2FAResponse>(PATHS.verify2FA)
}

export const useVerify2FALogin = () => {
  return useCreate<Verify2FALoginRequest, Verify2FALoginResponse>(PATHS.verify2FALogin)
}

export const useContactSales = () => {
  return useCreate<{
    email: string
    full_name: string
    phone?: string
    notes?: string
    requestors?: number
    approvers?: number
    monthly_requests?: number
    features?: string
    type: ContactType
  }>(PATHS.contactSales)
}

export const useChangeTeam = () => {
  return usePatch<{ token: string }>(PATHS.team)
}

export const useGetSettings = () => {
  return useGetOne<UserSettings>([AccountKeys.Settings], PATHS.settings, {
    query: {
      suspense: true,
    },
  })
}

export const useUpdateSettings = () => {
  const cache = useQueryClient()
  return usePatch<Partial<UserSettings>>(PATHS.settings, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountKeys.Settings])
      },
    },
  })
}

export { AccountKeys as Keys }
